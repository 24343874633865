
import Vue from 'vue';
import { OrderService } from '../../../services/order-summary';
import tacticTable from '../../components/summary/components/tacticTables/tactic-table.vue';
import util from '../../../util';
import debounce from 'lodash.debounce';
import { C360Icon } from '@c360/ui';
import analytics from '../../../mixins/analytics';

const orderService = new OrderService();

export default Vue.extend({
  name: 'OrderDetailsCampaignList',
  data: (): {
    metrics: object[];
    isLoading: boolean;
    data: object[];
    itemsPerPageOptions: number[];
    itemsPerPage: number;
    fetchedItems: string[];
    search: string;
  } => ({
    metrics: [],
    isLoading: false,
    data: null,
    itemsPerPageOptions: [5, 10, 15],
    itemsPerPage: 5,
    fetchedItems: [],
    search: '',
  }),
  components: { tacticTable, C360Icon },
  mixins: [analytics],
  created() {
    this.getData();
  },
  computed: {
    showNoDataMsg(): boolean {
      return this.$store.state.order.hidingOrderModulesWhenNoImps;
    },
  },
  methods: {
    goToMetric() {
      const product = this.$route.query?.product.toUpperCase();
      const element = this.$refs[product];

      // Get element's top coordinate minus offset of header
      const targetPosition = element['0'].$el.getBoundingClientRect().top + window.scrollY - 120;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    },
    async getData() {
      await this.fetchDataItem();
      if (this.$route.query?.product) {
        this.goToMetric();
      }
    },
    getDataDebounced: debounce(function (): void {
      this.analyticTrack(
        this.trackValue.ORDERS_SEARCH_CAMPAIGNS,
        `Search Value: ${this.search}, Order ID: ${this.$route.query?.orderId}`,
      );
      this.getData();
    }, 2000),
    async fetchDataItem(itemParam, isPagination) {
      try {
        if (!isPagination && !this.search) this.$store.dispatch('order/setHidingOrderModules', false);
        if (!isPagination) this.isLoading = true;
        if (itemParam?.tactic) this.fetchedItems.push(itemParam?.tactic);
        const campaignsData = await orderService.getCampaignList({
          advertiserId: this.$route.query?.id || 'N/A',
          orderId: this.$route.query?.orderId || 'N/A',
          tactic: itemParam?.tactic || null,
          limit: itemParam?.limit || this.itemsPerPage,
          offset: itemParam?.offset || 0,
          search: itemParam?.search || this.search,
        });
        if (!campaignsData?.performance?.length) {
          if (itemParam?.tactic) this.fetchedItems = this.fetchedItems.filter(item => item !== itemParam?.tactic);
          this.data = [];
          return;
        }

        // DASH-4883: If response has only BROADCAST campaigns, hide all modules except campaign list
        // sometimes we receive other products w/out campaigns - filter them out
        const campaignsPerformanceData = campaignsData?.performance || [];
        const filteredCampaignData = campaignsPerformanceData.filter(campaign => campaign.campaigns.length);
        const hasOnlyBroadcast = filteredCampaignData.length === 1 && filteredCampaignData[0].type === 'BROADCAST';
        if (hasOnlyBroadcast) {
          this.$store.dispatch('order/setHidingOrderModules', true);
        }

        const campaignsToDisplay = campaignsData.performance.filter(obj => obj.campaigns.length);
        campaignsToDisplay.forEach(tactic => {
          tactic.tacticName = util.tacticTitleMap(tactic.type);
          tactic.campaigns.map(campaign => {
            campaign.tacticName = util.tacticTitleMap(tactic.type);
            return campaign;
          });
        });
        if (!isPagination) {
          this.data = campaignsToDisplay;
        } else {
          for (const [key, value] of Object.entries(this.data)) {
            if (value.type === itemParam?.tactic) {
              value.campaigns = campaignsToDisplay[0]?.campaigns;
              value.offset = campaignsToDisplay[0]?.offset;
              value.currentPage = campaignsToDisplay[0]?.currentPage;
            }
          }
          if (itemParam?.tactic) this.fetchedItems = this.fetchedItems.filter(item => item !== itemParam?.tactic);
        }
      } catch (error) {
        console.error(`Error fetching data for ${name}:`, error);
      } finally {
        this.isLoading = false;
      }
    },
    async paginateCampaigns(tactic, offset) {
      const params = {
        tactic,
        offset,
        limit: this.itemsPerPage,
        search: this.search,
      };
      await this.fetchDataItem(params, true);
    },
    setItemsPerPage(option) {
      this.analyticTrack(
        this.trackValue.ORDERS_CHANGE_NUMBER_OF_ITEMS,
        `New value: ${option}, Order ID: ${this.$route.query?.orderId}`,
      );
      this.itemsPerPage = option;
    },
    tableHeaders(tactic: string): any {
      const rules = {
        Tactics: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV'],
        Impressions: [
          'Display',
          'Digital Video (Video + OTT)',
          'Video',
          'CTV',
          'Facebook Ads',
          'SEM',
          // 'Digital Video',
          'YouTube',
          'GAM',
          'Sinclair RSN',
          'Video - O&O',
          'Display - O&O',
          'Broadstreet - O&O',
          'Audio',
          'InnovidXP',
          'True Geo',
        ],
        ImpressionGoal: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV', 'Audio', 'True Geo'],
        ImpressionGoalPercent: [
          'Display',
          'Digital Video (Video + OTT)',
          'Video',
          'CTV',
          'Display - O&O',
          'Audio',
          'True Geo',
        ],
        Views: ['Email Marketing'],
        Clicks: ['Email Marketing', 'Broadstreet - O&O'],
        Hovers: ['Broadstreet - O&O'],
        ClickToViewRate: ['Email Marketing'],
        ClickThrough: ['SEM'],
        TotalProcessedCalls: ['Call Tracking'],
        DurationAvg_ProcessedCalls: ['Call Tracking'],
        Aired: ['TV'],
        Visits: ['TV'],
        VisitsAiring: ['TV'],
        all: ['Pacing', 'FriendlyName', 'StartDate', 'EndDate'],
      };

      const orderByValue = [
        'Pacing',
        'FriendlyName',
        'Tactics',
        'StartDate',
        'EndDate',
        'Impressions',
        'ImpressionGoal',
        'ImpressionGoalPercent',
        'Views',
        'Clicks',
        'ClickToViewRate',
        'ClickThrough',
        'TotalProcessedCalls',
        'DurationAvg_ProcessedCalls',
        'Hovers',
        'Aired',
        'Visits',
        'VisitsAiring',
      ];

      const headers = orderByValue.reduce((all: Header[], value: string) => {
        const header = { align: 'left', text: 'Campaign name', value };
        if (value === 'FriendlyName') {
          header['sortable'] = false;
          header['width'] = '50%';
          if (this.template === 'centeredHeader') {
            header.text = '';
          }
        } else {
          if (value === 'Impressions') {
            if (tactic.toLowerCase() === 'sinclair rsn') header['text'] = 'Household Imps';
            else header['text'] = 'Imps All Time';
          } else if (value === 'Pacing') {
            header['width'] = '90px';
            header['text'] = util.headerNamesMap(value);
          } else if (value === 'Tactics') {
            header['text'] = 'Products';
          } else {
            header['text'] = util.headerNamesMap(value);
          }
        }

        if ((rules[value] && rules[value].includes(tactic)) || rules.all.includes(value)) {
          all.push(header);
        }
        return all;
      }, []);

      headers.forEach(header => {
        const minNumberOfSymbolsInLargePacing = 9;
        const data = this.data.find(obj => obj.tacticName === tactic);
        const hasLargePacing = data?.campaigns?.some(
          campaign => campaign?.Pacing?.length > minNumberOfSymbolsInLargePacing,
        );

        if (header['value'] === 'FriendlyName') {
          header['width'] = hasLargePacing ? '48%' : '50%';
          return;
        }
        if (header['value'] === 'Pacing') {
          if (hasLargePacing) {
            header['width'] = '130px';
          } else {
            header['width'] = '90px';
          }
          return;
        }
      });
      // console.log(headers);
      return headers;
    },
  },
  watch: {
    itemsPerPage: {
      handler(): void {
        // reset to page one when items per page changes
        this.getData();
      },
    },
    search: {
      handler(): void {
        // reset to page one when search changes
        this.getDataDebounced();
      },
    },
  },
});
