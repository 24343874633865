
import Vue from 'vue';
import analytics from '../../../mixins/analytics';
import { formatDateForTopMetrics } from '../orderUtils';
import { componentConfig, componentHeight } from './utils/configSmallLineChart';
import { OrderService } from '../../../services/order-summary';
import { getRangePreviousText } from '../../components/homeController/components/utils/utils';

const orderService = new OrderService();

const orderMetricsMapping = {
  active: 'Active Orders',
  pending: 'Pending Orders',
  endingSoon: 'Ending Soon',
  expired: 'Expired Orders',
};

export default Vue.extend({
  name: 'OrderMetrics',
  data: (): {
    selectedRange: any;
    metrics: Record<string, any>[];
    loading: boolean;
    dateRange: string | null;
    activeMetric: string;
    componentHeight: string;
    daterangeOptions: any;
  } => ({
    selectedRange: null,
    dateRange: null,
    loading: false,
    metrics: [],
    activeMetric: 'Active Orders',
    componentHeight,
    daterangeOptions: [
      {
        name: 'Last 7 Days',
        key: 'thisweek',
      },
      {
        name: 'Last 30 Days',
        key: 'thismonth',
      },
      {
        name: 'Last 365 Days',
        key: 'thisyear',
      },
      {
        name: 'All Time',
        key: 'alltime',
      },
    ],
  }),
  components: {
    LineChartSmall: () => import('../../components/charts/line/lineChartSmall.vue'),
  },
  mixins: [analytics],
  created() {
    this.setDefaultDateRange();
    this.callAllMetrics();
  },
  computed: {
    orderListDaterange() {
      return this.$store.state.order.orderListParams.daterange;
    },
    dateRangeReadable() {
      return this.daterangeOptions.find(option => option.key === this.selectedRange)?.name;
    },
    componentConfig() {
      return { ...componentConfig, lineSeriesKeys: ['On This Date', getRangePreviousText(this.selectedRange)] };
    },
  },
  methods: {
    getTooltip(metric) {
      return [this.componentConfig?.tooltips?.find(m => m.key === metric.key)];
    },
    selectMetric(metric) {
      if (!metric.total) return;
      this.activeMetric = metric.title;
      const orderParams = this.$store.state.order.orderListParams;
      this.analyticTrack(this.trackValue.ORDERS_FILTER_BY, `Filter by Type: ${this.activeMetric}`);
      this.$store.dispatch('order/setOrderlistParams', {
        ...orderParams,
        filterBy: metric.key,
        offset: 0,
      });
    },
    setDefaultDateRange() {
      this.dateRange = 'thisyear';
      this.selectedRange = 'thisyear';
    },
    async callAllMetrics() {
      this.metrics = [];
      this.getOrderMetrics();
    },

    async getOrderMetrics() {
      const method = this.componentConfig?.fetchData?.method;
      try {
        this.loading = true;
        const fetchFunction = orderService[method];
        if (!fetchFunction) {
          this.error = 'Error fetching';
          console.error('Provide correct service');
          return;
        }
        const metrics = await fetchFunction({
          daterange: this.dateRange,
          advertiserId: this.$route.query?.id || 'N/A',
        });
        for (const key of this?.componentConfig?.availableMetrics) {
          const dataForMetric = metrics[key];
          this.metrics.push({
            key: key,
            title: orderMetricsMapping[key],
            percentage: dataForMetric?.total?.percentage || 0,
            total: dataForMetric?.total?.activeTotal || 0,
            chartData: this.splitData(dataForMetric?.data, this.dateRange),
          });
        }
        return this.metrics;
      } catch (error) {
        console.error('Error fetching', error);
      } finally {
        this.loading = false;
      }
    },

    splitData(data) {
      if (data.length % 2 !== 0) {
        data.shift();
      }
      const daysArray = [];
      const firstPartArray = [];
      const remainingPartArray = [];
      const midpoint = Math.floor(data.length / 2);
      const firstHalf = data.slice(0, midpoint);
      const secondHalf = data.slice(midpoint);
      if (this.dateRange !== 'alltime') {
        firstHalf.forEach(entry => {
          const { active } = entry;
          firstPartArray.push(active);
        });
        secondHalf.forEach(entry => {
          const { day, date, active } = entry;
          daysArray.push(formatDateForTopMetrics(day) || formatDateForTopMetrics(date));
          remainingPartArray.push(active);
        });
        return [daysArray, remainingPartArray, firstPartArray];
      } else {
        data.forEach(entry => {
          const { day, date, active } = entry;
          daysArray.push(formatDateForTopMetrics(day) || formatDateForTopMetrics(date));
          firstPartArray.push(active);
        });
        return [daysArray, firstPartArray];
      }
    },
  },
  watch: {
    orderListDaterange: {
      handler(newVal) {
        this.dateRange = newVal;
        this.selectedRange = newVal;
        this.callAllMetrics();
      },
    },
  },
});
